// see https://github.com/meinto/react-native-event-listeners/blob/master/index.js
// using a modified version directly in pwa since this library is not compatible with webpack
class EventRegister {
  static Listeners: any = {
    count: 0,
    refs: {}
  };

  static addEventListener(eventName: string, callback: Function) {
    EventRegister.Listeners.count = EventRegister.Listeners.count + 1;
    const eventId = 'l' + EventRegister.Listeners.count;
    EventRegister.Listeners.refs[eventId] = {
      name: eventName,
      callback
    };
    return eventId;
  }

  static removeEventListener(id: string) {
    return delete EventRegister.Listeners.refs[id];
  }

  static removeAllListeners() {
    let removeError = false;
    Object.keys(EventRegister.Listeners.refs).forEach((id: string) => {
      const removed = delete EventRegister.Listeners.refs[id];
      removeError = (!removeError) ? !removed : removeError;
    });
    return !removeError;
  }

  static emitEvent(eventName: string, data: any) {
    Object.keys(EventRegister.Listeners.refs).forEach((id: string) => {
      if (
        EventRegister.Listeners.refs[id] &&
        eventName === EventRegister.Listeners.refs[id].name
      ) {
        EventRegister.Listeners.refs[id].callback(data);
      }
    });
  }

  /*
   * shortener
   */
  static on(eventName: string, callback: Function) {
    return EventRegister.addEventListener(eventName, callback);
  }

  static rm(eventName: string) {
    return EventRegister.removeEventListener(eventName);
  }

  static rmAll() {
    return EventRegister.removeAllListeners();
  }

  static emit(eventName: string, data: any) {
    EventRegister.emitEvent(eventName, data);
  }
} 

export { EventRegister };
