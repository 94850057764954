"use strict";

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RestClient = void 0;
const axios_1 = require("axios");
const jwt_decode_1 = require("jwt-decode");
const properties_1 = require("./../config/properties");
class RestClient {
  getInstance(token) {
    const instance = axios_1.default.create({
      baseURL: properties_1.properties.apiBaseUrl
    });
    if (token) {
      instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      instance.defaults.headers.common['Authorization'] = '';
    }
    // timeout after 180 secs
    instance.defaults.timeout = 180 * 1000;
    return instance;
  }
  getNewTokens(accessToken, refreshToken) {
    return __awaiter(this, void 0, void 0, function* () {
      let tokensUpdated = false;
      let newTokens = {
        access_token: accessToken,
        refresh_token: refreshToken
      };
      try {
        if (accessToken && refreshToken) {
          let refreshRequired = false;
          const decodedToken = jwt_decode_1.jwtDecode(accessToken);
          if (decodedToken) {
            const DELTA_SECS = 120;
            const currentTimeSecs = Math.round(Date.now() / 1000);
            const diff = decodedToken.exp - currentTimeSecs;
            console.log('getNewTokens current diff', diff);
            if (diff <= DELTA_SECS) {
              refreshRequired = true;
            }
          }
          if (refreshRequired) {
            const url = '/token';
            const input = {
              grant_type: 'refresh_token',
              refresh_token: refreshToken
            };
            const instance = this.getInstance('');
            const response = yield instance.post(url, input);
            const responseJson = response.data;
            if (responseJson.success) {
              console.log('getNewTokens api call successful');
              const defTokens = {
                access_token: '',
                refresh_token: ''
              };
              newTokens = responseJson.data.tokens || defTokens;
              tokensUpdated = true;
            }
          }
        }
      } catch (err) {
        console.error('getNewTokens error', err);
      }
      return [tokensUpdated, newTokens];
    });
  }
}
const RestClientInstance = new RestClient();
exports.RestClient = RestClientInstance;