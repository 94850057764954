"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
const mobx_1 = require("mobx");
const risesharp_common_1 = require("risesharp-common");
const base_1 = require("./base");
class Container extends base_1.Base {
  constructor() {
    super({});
    this.resetState = mobx_1.action(() => {
      this.resetObservable();
    });
    // IMPORTANT - initialize token and user details state from pwa/jsn component
    this.loadState = mobx_1.action((userDetails, collegeDetails, employerDetails) => {
      if (userDetails && userDetails.tokens && userDetails.tokens.access_token && userDetails.tokens.refresh_token) {
        this.state.isUserLoggedIn = true;
      } else {
        this.state.isUserLoggedIn = false;
      }
      this.state.userDetails = userDetails;
      this.state.collegeDetails = collegeDetails;
      this.state.employerDetails = employerDetails;
    });
    this.resetState();
  }
  resetStateOnSignout() {
    this.resetObservable();
  }
  resetObservable() {
    mobx_1.extendObservable(this, {
      state: Object.assign(this.state, {
        isToggleMenuOpen: false,
        isDropdownMenuOpen: false,
        isUserLoggedIn: false,
        userDetails: risesharp_common_1.Schema.getAuthenticatedUserDetails(),
        collegeDetails: risesharp_common_1.Schema.getCollegeDetails(),
        employerDetails: risesharp_common_1.Schema.getEmployerDetails(),
        isInternetOnline: true,
        internetChkIntervalId: -1,
        maintenanceMessageDetails: risesharp_common_1.Schema.getMaintenanceMessageDetails(),
        maintMsgIntervalId: -1,
        studentTaskIntervalId: -1,
        studentEmplSkillsIntervalId: -1,
        checkLogoutIntervalId: -1,
        studentCollegeInfo: {},
        signupPricing: {}
      })
    });
  }
}
exports.default = Container;