import { Lock } from 'async-await-mutex-lock';

import { UiTS, Constants } from 'risesharp-common';
import { RestClient } from 'risesharp-ui';

import { Helper } from './helper';
import { LocalStorage } from './localStorage';
import { EventRegister } from './eventRegister';

// lock
const lock: Lock<string> = new Lock();

class ServiceInput {
  async prepareInput(props: any, args: UiTS.RestClientInputArgs) {
    const accessToken = await this.getAccessToken(props);
    
    const obj = {} as UiTS.RestClientInput;
    obj.token = accessToken;
    obj.apiArgs = args;
    if (!obj.apiArgs.hasOwnProperty('isMobileApp')) {
      obj.apiArgs.isMobileApp = false;
    }
    return obj;
  }

  private async getAccessToken(props: any) {
    let token = '';

    const key = 'refresh_token_lock';
    try {
      await lock.acquire(key);
      // console.log('refresh_token_lock acquired');

      let accessToken = Helper.getAuthorizedToken(props);
      let refreshToken = Helper.getRefreshToken(props);
      if (accessToken && refreshToken) {
        const userDetails: any = LocalStorage.getUserDetails();
        if (userDetails && userDetails.tokens) {
          const checkAccessToken = userDetails.tokens.access_token || '';
          const checkRefreshToken = userDetails.tokens.refresh_token || '';
          if ((checkAccessToken && checkRefreshToken) && (checkAccessToken !== accessToken && checkRefreshToken !== refreshToken)) {
            // possibility of tokens in localStorage not in sync with store after obtaining new tokens
            console.log('localstorage and store not in sync');
            accessToken = checkAccessToken;
            refreshToken = checkRefreshToken;
          }
        }
        
        const [tokensUpdated, newTokens] = await RestClient.getNewTokens(accessToken, refreshToken);
        if (tokensUpdated) {
          EventRegister.emit(Constants.TOKEN_CHANGE_LISTENER, newTokens);
          await Helper.sleep(500) ; // wait for event sync?
          console.log('getAccessToken new tokens released');
        }
        token = newTokens.access_token || '';
      }
    } catch (err) {
      console.error('getAccessToken error', err);
    }  finally {
      lock.release(key);
      // console.log('refresh_token_lock released');
    }

    return token;
  }
}

const ServiceInputInstance = new ServiceInput();
export { ServiceInputInstance as ServiceInput };
